import { BaseDto } from "../baseDto"
/* SFLoginLogBaseDto generated at 2024-09-25 15:25:20.681 +10:00*/
export class SFLoginLogBaseDto extends BaseDto {
	LoginLogId?: string;
	Username?: string;
	LoginTime?: Date;
	SourceIP?: string;
	LoginType?: string;
	Status?: string;
	Browser?: string;
	Platform?: string;
	Application?: string;
	ClientVersion?: string;
	APIType?: string;
	APIVersion?: string;
	LoginURL?: string;
	Experience?: string;
	TLSProtocol?: string;
	TLSDecipher?: string;
	CountryCode?: string;
	Country?: string;
	Subdivision?: string;
	City?: string;
	PostalCode?: string;
	Latitude?: string;
	Longitude?: string;
	HttpMethod?: string;
	AuthMethod?: string;
	LoginSubtype?: string;
}
