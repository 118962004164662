import { BaseDto } from "../baseDto"
/* DbResourceVersionBaseDto generated at 2024-09-25 15:25:20.379 +10:00*/
export class DbResourceVersionBaseDto extends BaseDto {
	DbResourceVersionId?: string;
	SystemResourceId?: string;
	ResourceDbType?: string;
	Version?: number;
	ApplyDate?: Date;
	DateCompleted?: Date;
	UpgradeDbScript?: string;
	StatusCode?: string;
}
