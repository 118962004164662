import { BaseDto } from "../baseDto"
/* SystemModelRelationshipBaseDto generated at 2024-09-25 15:25:20.760 +10:00*/
export class SystemModelRelationshipBaseDto extends BaseDto {
	SystemModelRelationshipId?: string;
	SystemModelId?: string;
	IsExtensionModel?: boolean;
	ForeignSystemModelId?: string;
	ForeignSystemModelFieldId?: string;
	OrgDbVersion?: number;
	StatusCode?: string;
}
