import { BaseDto } from "../baseDto"
/* ProjectItemGroupBaseDto generated at 2024-09-25 15:25:20.596 +10:00*/
export class ProjectItemGroupBaseDto extends BaseDto {
	ProjectItemGroupId?: string;
	ProjItemGroupName?: string;
	ProjItemGroupDescription?: string;
	ProjectId?: string;
	Org_ProjectId?: string;
	Org_ProjectItemGroupId?: string;
	StatusCode?: string;
}
