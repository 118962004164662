import { BaseDto } from "../baseDto"
/* ARLRequestBaseDto generated at 2024-09-25 15:25:20.361 +10:00*/
export class ARLRequestBaseDto extends BaseDto {
	ARLRequestId?: string;
	RequestSource?: string;
	EnvironmentCode?: string;
	OrganisationId?: string;
	ProjectId?: string;
	ProjectVersionNo?: number;
	ProjectJSON?: string;
	DateRequested?: Date;
	DateCompleted?: Date;
	IsARLActivated?: boolean;
	PREP_OrganisationId?: number;
	PREP_ProjectId?: number;
	ARLRequestReference?: string;
	DateARLRequested?: Date;
	DateARLCompleted?: Date;
	ARLRequestJSON?: string;
	ARLResponseJSON?: string;
	ARLArtwork?: string;
	ARLReport?: string;
	ExceptionJSON?: string;
	RetryCount?: number;
	StatusCode?: string;
}
