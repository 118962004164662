import { BaseDto } from "../baseDto"
/* WARLUpdateLogBaseDto generated at 2024-09-25 15:25:20.822 +10:00*/
export class WARLUpdateLogBaseDto extends BaseDto {
	WebARLUpdateLogId?: string;
	Material?: string;
	UpdateDescription?: string;
	ProgramRegion?: string;
	ProgramStatus?: string;
	GoLiveDate?: Date;
	DisplayGoLiveDateText?: string;
	PREPUpdateTimeline?: string;
	ArtworkUpdateTimeline?: string;
	StatusCode?: string;
}
