import { BaseDto } from "../baseDto"
/* ScheduledTaskBaseDto generated at 2024-09-25 15:25:20.639 +10:00*/
export class ScheduledTaskBaseDto extends BaseDto {
	ScheduledTaskId?: string;
	TaskCode?: string;
	TaskName?: string;
	TaskDescription?: string;
	ScheduleType?: string;
	RecurType?: string;
	OnceDate?: Date;
	OnceTime?: Date;
	RecurDays?: number;
	RecurWeeks?: number;
	IsMon?: boolean;
	IsTue?: boolean;
	IsWed?: boolean;
	IsThur?: boolean;
	IsFri?: boolean;
	IsSat?: boolean;
	IsSun?: boolean;
	IsDayOfMonth?: boolean;
	RecurDayOfMonth?: number;
	RecurMonths?: number;
	RecurWeekType?: string;
	RecurDayOfWeek?: string;
	RecurEveryMonths?: number;
	IsOccurOnce?: boolean;
	OccurOnceTime?: Date;
	OccursEveryType?: string;
	OccurRate?: number;
	OccurStartTime?: Date;
	OccurEndTime?: Date;
	StartDate?: Date;
	EndDate?: Date;
	IsNoEndDate?: boolean;
	IsEnabled?: boolean;
	LastRunStartDate?: Date;
	LastRunEndDate?: Date;
	NextRunDate?: Date;
	LastErrorMessage?: string;
	StatusCode?: string;
}
