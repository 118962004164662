import { BaseDto } from "../baseDto"
/* PageContentBaseDto generated at 2024-09-25 15:25:20.526 +10:00*/
export class PageContentBaseDto extends BaseDto {
	PageContentId?: string;
	PageContentSetId?: string;
	PageTitle?: string;
	ContentType?: string;
	ContentCode?: string;
	ContentName?: string;
	ContentDesription?: string;
	ContentValue?: string;
	PublishedContent?: string;
	PreviousContent?: string;
	DateActiveFrom?: Date;
	LevelNo?: number;
	SequenceNo?: number;
	ParentPageContentId?: string;
	StatusCode?: string;
}
