import { BaseDto } from "../baseDto"
/* ProductComponentGroupBaseDto generated at 2024-09-25 15:25:20.545 +10:00*/
export class ProductComponentGroupBaseDto extends BaseDto {
	ProductComponentGroupId?: string;
	ProdCompGroupName?: string;
	ProdCompGroupDescription?: string;
	ProductId?: string;
	ProductVersionNo?: number;
	ProjectId?: string;
	ProjectItemGroupId?: string;
	ProjectItemId?: string;
	Org_ProdCompGroupId?: string;
	Org_ProductId?: string;
	Org_ProjectId?: string;
	Org_ProjectItemGroupId?: string;
	Org_ProjectItemId?: string;
	StatusCode?: string;
}
