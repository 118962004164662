import { BaseDto } from "../baseDto"
/* SFAccountContactRelationBaseDto generated at 2024-09-25 15:25:20.666 +10:00*/
export class SFAccountContactRelationBaseDto extends BaseDto {
	Id?: string;
	AccountId?: string;
	ContactId?: string;
	Roles?: string;
	IsDirect?: boolean;
	IsActive?: boolean;
	StartDate?: Date;
	EndDate?: Date;
	IsDeleted?: boolean;
	CreatedDate?: Date;
	CreatedById?: string;
	LastModifiedDate?: Date;
	LastModifiedById?: string;
	SystemModstamp?: Date;
	Access_to_ARL__c?: boolean;
	Access_to_Member_Centre__c?: boolean;
	Access_to_PREP__c?: boolean;
	External_Id__c?: string;
	IsAccountsContact__c?: boolean;
	IsPrimary__c?: boolean;
	Password_Hash__c?: string;
	Confirmed_Contact_At__c?: Date;
	Access_to_Reporting_Tool__c?: boolean;
	Access_to_MC_support_field__c?: boolean;
	IsExecutive__c?: boolean;
	Primary_PREP_User__c?: boolean;
	Additional_PREP_License_Assigned_On__c?: Date;
	Additional__c?: boolean;
	PREP_License_Extended_Until__c?: Date;
	Additional_PREP_License_Expiry__c?: Date;
	Contact_Email__c?: string;
	Contact_Name__c?: string;
	Additional_PREP_License_Expired__c?: boolean;
	Record_Prior_Primary_PREP_User__c?: boolean;
	isANZPACKeyContact__c?: boolean;
	ARLMP_Heroku_ID__c?: string;
	ARL_MP_Buyer_Full_License_Assigned__c?: Date;
	ARL_MP_Buyer_Guest_License_Assigned__c?: Date;
	ARL_MP_Vendor_Full_License_Assigned__c?: Date;
	ARL_MP_Vendor_Guest_License_Assigned__c?: Date;
	ARL_Vendor_Training_Completed__c?: Date;
	Access_to_ARLMP_Buyer__c?: boolean;
	Access_to_ARLMP_Vendor__c?: boolean;
	Access_to_PREP_non_Member__c?: boolean;
	PREP_Contact_Id__c?: string;
}
