<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
  <div class="sticky-top">
    <div class="flex-wrap bg-white">
      <div class="body-container flex-inline">
        <arl-header [pagePath]="pagePath" class="flex-wrap"></arl-header>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-darkturquoise">
    <div class="body-container p2 mt-4 mb-4">
      <div class="flex-wrap">
        <div class="flex-wrap p2">
          <div class="flex-wrap fc-white">
            <h2 class="govheaderfontsize">GOVERNANCE</h2>
          </div>
          <div class="flex-wrap fc-white">
            <h1 class="heading text headinglineheight">Update logs</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="flex-wrap bgc-white">
    <div class="body-container p2 ">
      <div class="flex-wrap mt-7">
        <div class="flex-wrap">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">PREP update log</h2>
          </div>
          <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              Any updates to PREP, including functional updates (improved user experience) or program
              updates (e.g. thresholds, recyclability classification changes) will be logged in the PREP
              updates log. To support Users with understanding timelines for PREP report and on-pack
              artwork updates for each change, this information has been included within the table as a
              reference.
            </p>
          </div>
        </div>
        <div class="flex-wrap  p2 bgc-gray mt-4">
          <div class="flex-wrap flex-inline">
            <div class="flex-fill">&nbsp;</div>
            <div class="right text-underline font-bold fc-blue" style="font-size:16px!important;" (click)="showUpdateLogFilter()">
              Filter
            </div>
          </div>
          <div class="flex-wrap mt-4">
            <home-update-log-list #updateLogListComponent class="flex-wrap" style="height: calc(100%);"
                                  [name]="'updateLogList'" [(isFilterVisible)]="isUpdateLogFilterVisible" [isFromAdmin]="false">
            </home-update-log-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="body-container p2 mt-8 mb-8">
      <div class="flex-wrap ">
        <div class="flex-wrap">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">ARL program historic log</h2>
          </div>
          <div class="flex-wrap parentfont psection">
            <p class="fontsize">
              Items from the ARL program public consultation table are regularly archived as they are
              reviewed and closed. All closed ARL program updates to date are
              listed below.
            </p>
          </div>
        </div>
        <div class="flex-wrap  p2 bgc-gray mt-4">
          <div class="flex-wrap flex-inline">
            <div class="flex-fill">&nbsp;</div>
            <div class="right text-underline font-bold fc-blue" style="font-size:16px!important;" (click)="showSubmissionLogFilter()">
              Filter
            </div>
          </div>
          <div class="flex-wrap mt-4">
            <home-submission-list #submissionListComponent class="flex-wrap" style="height: calc(100%);" id="submissionList"
                                  [name]="'submissionList'" [isHistorical]="true" [isSubmission]="false" [(isFilterVisible)]="isSumissionLogFilterVisible" [isFromAdmin]="false">
            </home-submission-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="body-container p2 mt-8 mb-8">
      <div class="flex-wrap ">
        <div class="flex-wrap">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">ARL program submissions log</h2>
          </div>
          <div class="flex-wrap parentfont psection">
            <p class="fontsize">
              Please find below the list of currently approved soft plastics and fibre submissions within the ARL program.
            </p>
          </div>
        </div>
        <div class="flex-wrap  p2 bgc-gray mt-4">
          <div class="flex-wrap flex-inline">
            <div class="flex-fill">&nbsp;</div>
            <div class="right text-underline font-bold fc-blue" style="font-size:16px!important;" (click)="showSubmissionOnlyFilter()">
              Filter
            </div>
          </div>
          <div class="flex-wrap mt-4">
            <home-submission-list #submissionListComponent class="flex-wrap" style="height: calc(100%);" id="submissionOnlyList"
                                  [name]="'submissionOnlyList'" [isHistorical]="true" [isSubmission]="true" [(isFilterVisible)]="isSubmissionOnlyFilterVisible" [isFromAdmin]="false">
            </home-submission-list>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap">
    <gov-footer [pagePath]="pagePath" class="flex-wrap"></gov-footer>
  </div>
</div>
