import { BaseDto } from "../baseDto"
/* ANZPACDetailBaseDto generated at 2024-09-25 15:25:20.280 +10:00*/
export class ANZPACDetailBaseDto extends BaseDto {
	ANZPACDetailId?: string;
	ANZPACReportId?: string;
	SectionType?: string;
	Sequence?: number;
	ItemName?: string;
	ItemDescription?: string;
	IsApplicable?: boolean;
	TotalTonnes1?: number;
	TotalTonnes2?: number;
	TotalTonnes3?: number;
	TotalAmount1?: number;
	TotalAmount2?: number;
	TotalAmount3?: number;
	Percentage1?: number;
	Percentage2?: number;
	Percentage3?: number;
	AccuracyLevel?: string;
	AcivityDescription?: string;
	ActivityDate?: Date;
	ActivityOutcome?: string;
	RecoverabilityType?: string;
	RecycledContentType?: string;
	EliminationMeans?: string;
	Evidence?: string;
	Country?: string;
	Region?: string;
	OrganisationsInvolved?: string;
	StatusCode?: string;
}
