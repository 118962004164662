import { BaseDto } from "../baseDto"
/* ObjectAttributeBaseDto generated at 2024-09-25 15:25:20.489 +10:00*/
export class ObjectAttributeBaseDto extends BaseDto {
	ObjectAttributeId?: string;
	AttributeSetId?: string;
	ClassificationTypeCode?: string;
	ObjAttrVersionNo?: number;
	ObjectTypeCode?: string;
	IsAttributeEditable?: boolean;
	ObjName?: string;
	ObjDescription?: string;
	AttributeName?: string;
	DisplayValue?: string;
	DisplayClass?: string;
	TipValue?: string;
	LevelNo?: number;
	PrimaryKeyName?: string;
	PrimaryKeyDisplayName?: string;
	ParentObjAttrId?: string;
	AttributeTypeCode?: string;
	SequenceNo?: number;
	AttrDataSourceCode?: string;
	AttrDataSourceObjId?: string;
	AttrDataSourceName?: string;
	AttrDataTypeCode?: string;
	TextAttrLength?: number;
	ReferenceKeyField?: string;
	ReferenceDisplayField?: string;
	DataClass?: string;
	JSAvailabilityLogic?: string;
	JSLogic?: string;
	CSharpLogic?: string;
	Org_ObjectAttributeId?: string;
	Org_ParentObjAttrId?: string;
	Org_StatusCode?: string;
	StatusCode?: string;
}
