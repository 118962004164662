import { BaseDto } from "../baseDto"
/* IpAddressRangeLocationBaseDto generated at 2024-09-25 15:25:20.473 +10:00*/
export class IpAddressRangeLocationBaseDto extends BaseDto {
	IpAddressRangeLocationId?: string;
	FromIpRange?: number;
	ToIpRange?: number;
	City?: string;
	Region?: string;
	PostCode?: string;
	CountryCode?: string;
	CountryName?: string;
	LatitudeValue?: number;
	LongitudeValue?: number;
	TimeOffset?: string;
	TimezoneCode?: string;
	TimezoneName?: string;
	StatusCode?: string;
}
