import { BaseDto } from "../baseDto"
/* EmailFileBaseDto generated at 2024-09-25 15:25:20.401 +10:00*/
export class EmailFileBaseDto extends BaseDto {
	FileId?: string;
	RegisteredFileId?: string;
	EmailMessageId?: string;
	Sequence?: number;
	Filename?: string;
	External_ReferenceId?: string;
	StatusCode?: string;
}
