import { BaseDto } from "../baseDto"
/* ARLAssessmentResultBaseDto generated at 2024-09-25 15:25:20.327 +10:00*/
export class ARLAssessmentResultBaseDto extends BaseDto {
	ARLAssessmentResultId?: string;
	ProjectId?: string;
	ProjectVersionNo?: number;
	ProjectItemId?: string;
	AssessmentReportId?: number;
	AssessmentReportDate?: Date;
	AssessmentLocationCode?: string;
	PREP_LocationId?: number;
	SelfNominated?: string;
	TechnicalRecyclability?: string;
	CollectionStatus?: string;
	AssessmentResult?: string;
	ConditionalText?: string;
	IsCheckLocally?: boolean;
	ARLArtwork?: string;
	StatusCode?: string;
}
