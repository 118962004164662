import { BaseDto } from "../baseDto"
/* UserRoleBaseDto generated at 2024-09-25 15:25:20.791 +10:00*/
export class UserRoleBaseDto extends BaseDto {
	UserRoleId?: string;
	UserId?: string;
	OrganisationId?: string;
	RoleTypeCode?: string;
	RoleCode?: string;
	StatusCode?: string;
}
