<div *ngIf="showComponent" style="width:calc(100%);height:calc(100%);overflow:auto;"
     [ngClass]="{'mobile': IsMobileView,'portrait': IsPortraitView,'landscape': !IsPortraitView}">
  <div class="sticky-top">
    <div class="flex-wrap bg-white">
      <div class="body-container flex-inline">
        <arl-header [pagePath]="pagePath" class="flex-wrap"></arl-header>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-darkturquoise">
    <div class="body-container p2 mt-4 mb-4">
      <div class="flex-wrap">
        <div class="flex-wrap p2">
          <div class="flex-wrap fc-white">
            <h2 class="govheaderfontsize">GOVERNANCE</h2>
          </div>
          <div class="flex-wrap fc-white">
            <h1 class="heading text headinglineheight">Get involved</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="body-container p2">
      <div class="flex-wrap mt-7">
        <div class="flex-wrap">
          <div class="fc-dark-turquoise parentfont">
            <h2 class="contentheader h2section">Contribute to the ARL program</h2>
          </div>
          <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              The recycling context for packaging materials is always evolving. To reflect this, the ARL
              team is continuously reviewing and recalibrating the thresholds for priority materials and
              formats. Currently, we are seeking insights and evidence regarding the forward workplan
              program items below. If you would like to provide feedback, or suggest a material or format
              for consideration in the forward workplan, please reach out to the team via arl@apco.org.au.
            </p>
          </div>
          <div class="flex-wrap  p2 bgc-gray mt-8">
            <div class="flex-wrap flex-inline">
              <div class="fc-dark-turquoise parentfont">
                <h3 class="h3gov h2section pl-2 pt-2">ARL program workplan</h3>
              </div>
              <div class="right text-underline font-bold fc-blue" style="font-size:16px!important;" (click)="showProgramFilter()">
                Filter
              </div>
            </div>
            <div class="flex-wrap ">
              <home-program-list #programListComponent class="flex-wrap" style="height: calc(100%);"
                                 [name]="'programList'" [(isFilterVisible)]="isProgramFilterVisible"
                                 [isFromAdmin]="false">
              </home-program-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="body-container p2  ">
      <div class="flex-wrap mt-8">
        <div class="fc-dark-turquoise parentfont">
          <h2 class="contentheader h2section">Have your say</h2>
        </div>
        <div class="flex-wrap">
          <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              Before finalising any significant proposed changes, the ARL will always offer a right of
              response via public consultation. This is your opportunity to provide feedback on
              submissions and the final draft of proposed thresholds. Have your say below.
            </p>
          </div>
          <div class="flex-wrap mt-4 ">
            <div class="fc-dark-turquoise flex-wrap parentfont">
              <h3 class="h3gov h2section">ARL program threshold changes</h3>
            </div>
            <div class="flex-wrap parentfont">
              <p class="fontsize psection">
                APCO review the technical recyclability thresholds of the program either on a two-yearly
                basis, or as a result of known changes to the system impacting recyclability.
              </p>
            </div>
          </div>
          <div class="flex-wrap mt-4 ">
            <div class="fc-dark-turquoise flex-wrap parentfont">
              <h3 class="h3gov h2section">
                ARL program submissions
              </h3>
            </div>
            <div class="flex-wrap parentfont">
              <p class="fontsize psection">
                Factors such as changing kerbside access levels and emerging technologies and
                infrastructure influence the recyclability of packaging formats and materials in
                Australia and New Zealand. To manage these changes, the ARL program has a clear process
                available to all stakeholders. Organisations are welcome to make submissions with
                supporting evidence to APCO regarding recyclability, consumer behaviour and alternative
                destinations. These submissions are subject to public comment.
              </p>
            </div>
          </div>
        </div>
        <div class="flex-wrap  p2 bgc-gray mt-4">
          <home-submission-list #submissionListComponent class="flex-wrap " style="height: calc(100%);"
                                [name]="'submissionList'" [isHistorical]="false" [isSubmission]="false" [isFromAdmin]="false">
          </home-submission-list>
        </div>
        <div class="flex-wrap  font-bold mt-4">
          <p class="fontsize">*Note, Brand owner names will not be published until the submission review is complete.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap bgc-white">
    <div class="body-container p2 mt-8 mb-8">
      <div class="flex-wrap">
        <div class="fc-dark-turquoise parentfont">
          <h2 class="contentheader h2section">Interested in making an ARL program submission?</h2>
        </div>
        <div class="flex-wrap">
          <div class="flex-wrap parentfont">
            <p class="fontsize psection">
              The packaging and recycling industries are rapidly evolving spaces. To manage these changes,
              the ARL program offers a clear process to explore possible exemptions or additions to the
              program. Organisations are welcome to make various submissions to the ARL program, with
              supporting evidence for evaluation.
            </p>
          </div>
          <div class="flex-wrap mt-4">
            <div class="fc-dark-turquoise flex-wrap parentfont">
              <h3 class="h3gov h2section">ARL program submissions include:</h3>
            </div>
          </div>
        </div>
        <div class="flex-wrap p2 bgc-gray">
          <div class="flex-wrap mb-2">
            <div class="flex-wrap brad2 b1 p5 bgc-white" style="border-color:#E5E5E5;">
              <div class="flex-wrap">
                <div class="flex-fill mt-3 font-bold" (click)="isIRCVisible=!isIRCVisible">
                  <p class="fontsize">Recyclability evaluation submission </p>
                </div>
                <div class="w2 mt-3">
                  <div (click)="isIRCVisible=!isIRCVisible" class="flex-wrap">
                    &nbsp;<i class="fa-solid"
                             [ngClass]="{'fa-chevron-up':isIRCVisible,'fa-chevron-down':!isIRCVisible}"></i>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isIRCVisible}">
                <p class="mb-3 fontsize">
                  A recyclability evaluation submission may be made where an organisation disagrees with
                  the results generated by PREP, or requests a new packaging type or material to be
                  recognised under the ARL program.
                </p>
                <div class="flex-wrap">
                  <a class="fc-blue fontsize" target="_blank" rel="noopener noreferrer"
                     href="https://documents.packagingcovenant.org.au/other-public-documents/ARL+Program+Recyclability+Evaluation+Submission+Criteria+Form">
                    Recyclability
                    Evaluation Submission Criteria Form
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-wrap mb-2">
            <div class="flex-wrap brad2 b1 p5 bgc-white" style="border-color:#E5E5E5;">
              <div class="flex-wrap">
                <div class="flex-fill mt-3 font-bold" (click)="isMSCVisible=!isMSCVisible">
                  <p class="fontsize">Consumer behaviour submission</p>
                </div>
                <div class="w2 mt-3">
                  <div (click)="isMSCVisible=!isMSCVisible" class="flex-wrap">
                    &nbsp;<i class="fa-solid"
                             [ngClass]="{'fa-chevron-up':isMSCVisible,'fa-chevron-down':!isMSCVisible}"></i>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isMSCVisible}">
                <p class="mb-3 fontsize">
                  A consumer behaviour submission may be made where an organisation wants to add a new
                  consumer behaviour to the program.
                </p>
                <div class="flex-wrap">
                  <a class="fc-blue fontsize" target="_blank" rel="noopener noreferrer"
                     href="https://documents.packagingcovenant.org.au/other-public-documents/ARL+Program+Consumer+Behaviour+Submission+Criteria+Form">
                    Consumer Behaviour
                    Submission Criteria Form
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-wrap mb-2">
            <div class="flex-wrap brad2 b1 p5 bgc-white" style="border-color:#E5E5E5;">
              <div class="flex-wrap">
                <div class="flex-fill mt-3 font-bold" (click)="isNZASCVisible=!isNZASCVisible">
                  <p class="fontsize">Alternative destination submission</p>
                </div>
                <div class="w2 mt-3">
                  <div (click)="isNZASCVisible=!isNZASCVisible" class="flex-wrap">
                    &nbsp;<i class="fa-solid"
                             [ngClass]="{'fa-chevron-up':isNZASCVisible,'fa-chevron-down':!isNZASCVisible}"></i>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isNZASCVisible}">
                <p class="mb-3 fontsize">
                  An alternative destination is a recycling scheme that operates separately from kerbside
                  collection. Organisations operating such recycling schemes can apply to APCO to become
                  recognised as an alternative destination under the ARL program.
                </p>
                <div class="flex-wrap">
                  <a class="fc-blue fontsize" target="_blank" rel="noopener noreferrer"
                     href="https://documents.packagingcovenant.org.au/other-public-documents/ARL+Program+Alternative+Destination+Submission+Criteria+Form">
                    Alternative
                    Destination Submission Criteria Form
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-wrap mb-2">
            <div class="flex-wrap brad2 b1 p5 bgc-white" style="border-color:#E5E5E5;">
              <div class="flex-wrap">
                <div class="flex-fill mt-3 font-bold" (click)="isIRPVisible=!isIRPVisible">
                  <p class="fontsize">Fibre submission</p>
                </div>
                <div class="w2 mt-3">
                  <div (click)="isIRPVisible=!isIRPVisible" class="flex-wrap">
                    &nbsp;<i class="fa-solid"
                             [ngClass]="{'fa-chevron-up':isIRPVisible,'fa-chevron-down':!isIRPVisible}"></i>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isIRPVisible}">
                <p class="mb-3 fontsize">
                  Organisations may be eligible to make a fibre submission when they have packaging that
                  does not meet the Australian thresholds for fibre-based packaging, or are introducing a
                  new fibre-based material but have received a positive outcome after having tested to
                  APCO’s Standardised Test Method for Repulpability.
                </p>
                <div class="flex-wrap">
                  <a class="fc-blue fontsize" target="_blank" rel="noopener noreferrer" href="https://documents.packagingcovenant.org.au/other-public-documents/Fibre+Submissions+Process">
                    Fibre
                    Submission Process
                  </a>
                </div>
                <div class="flex-wrap">
                  <a class="fc-blue fontsize" target="_blank" rel="noopener noreferrer" href="https://documents.packagingcovenant.org.au/other-public-documents/Fibre+Submissions+Criteria+Form">
                    Fibre
                    Submission Criteria Form
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-wrap mb-2">
            <div class="flex-wrap brad2 b1 p5 bgc-white" style="border-color:#E5E5E5;">
              <div class="flex-wrap">
                <div class="flex-fill mt-3 font-bold" (click)="isSPCVisible=!isSPCVisible">
                  <p class="fontsize">Soft plastics submission</p>
                </div>
                <div class="w2 mt-3">
                  <div (click)="isSPCVisible=!isSPCVisible" class="flex-wrap">
                    &nbsp;<i class="fa-solid"
                             [ngClass]="{'fa-chevron-up':isSPCVisible,'fa-chevron-down':!isSPCVisible}"></i>
                  </div>
                </div>
              </div>
              <div class="flex-wrap mt-2" [ngClass]="{'hidden':!isSPCVisible}">
                <p class="mb-3 fontsize">
                  Organisations may be eligible to make a soft plastics submission when they
                  have packaging that does not meet the thresholds for soft plastics in Australia but have
                  evidence to confirm the recyclability of their packaging following testing to an
                  APCO-approved test methodology.
                </p>
                <div class="flex-wrap">
                  <a class="fc-blue fontsize" target="_blank" rel="noopener noreferrer" href="https://documents.packagingcovenant.org.au/other-public-documents/Soft+Plastics+Submissions+Process">
                    Soft
                    Plastics Submission Process
                  </a>
                </div>
                <div class="flex-wrap">
                  <a class="fc-blue fontsize" target="_blank" rel="noopener noreferrer" href="https://documents.packagingcovenant.org.au/other-public-documents/Soft+Plastics+Submission+Criteria+Form">
                    Soft
                    Plastics Submission Criteria Form
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-wrap mt-4 mb-4">
        <div class="center font-bold fontsize">
          If your organisation is interested in making a submission, please reach
          out to the ARL team via
          <a target="_blank" class="fontsize"
             href="mailto:arl@apco.org.au?subject=Feedback on ARL submissions">arl&#64;apco.org.au</a>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-wrap">
    <gov-footer [pagePath]="pagePath" class="flex-wrap"></gov-footer>
  </div>
</div>
