import { BaseDto } from "../baseDto"
/* DomainValueBaseDto generated at 2024-09-25 15:25:20.394 +10:00*/
export class DomainValueBaseDto extends BaseDto {
	DomainValueId?: string;
	DomainTypeId?: string;
	CodeValue?: string;
	NameValue?: string;
	DisplayValue?: string;
	Description?: string;
	Sequence?: number;
	IsDefault?: boolean;
	IsEnteredValue?: boolean;
	EnteredValueDataTypeCode?: string;
	TextValue?: string;
	NumberValue?: number;
	DateValue?: Date;
	BooleanValue?: boolean;
	JsonValue?: string;
}
