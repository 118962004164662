import { BaseDto } from "../baseDto"
/* IPAccessBaseDto generated at 2024-09-25 15:25:20.457 +10:00*/
export class IPAccessBaseDto extends BaseDto {
	IPAccessId?: string;
	IPAccessTypeCode?: string;
	UserId?: string;
	IPAddress?: string;
	AccessStatusCode?: string;
	EnvCode?: string;
	StatusCode?: string;
}
