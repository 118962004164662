import { BaseDto } from "../baseDto"
/* IpAddressLocationBaseDto generated at 2024-09-25 15:25:20.473 +10:00*/
export class IpAddressLocationBaseDto extends BaseDto {
	IpAddressLocationId?: string;
	IpAddress?: string;
	Hostname?: string;
	City?: string;
	Region?: string;
	PostCode?: string;
	CountryCode?: string;
	TimezoneCode?: string;
	TimezoneName?: string;
	DateLastRefreshed?: Date;
	DateLastSeen?: Date;
}
