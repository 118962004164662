import { BaseDto } from "../baseDto"
/* SFV1CovenantAnnualReportBaseDto generated at 2024-09-25 15:25:20.697 +10:00*/
export class SFV1CovenantAnnualReportBaseDto extends BaseDto {
	Id?: string;
	OwnerId?: string;
	IsDeleted?: boolean;
	Name?: string;
	CreatedDate?: Date;
	CreatedById?: string;
	LastModifiedDate?: Date;
	LastModifiedById?: string;
	SystemModstamp?: Date;
	LastViewedDate?: Date;
	LastReferencedDate?: Date;
	AR_Sect_1_Evidence__c?: string;
	AR_Sect_2_Evidence__c?: string;
	AR_Sect_3_Evidence__c?: string;
	AR_Sect_4_Evidence__c?: string;
	AR_Sect_5_Evidence__c?: string;
	AR_Sect_6_Evidence__c?: string;
	AR_Sect_7_Evidence__c?: string;
	AR_Sect_8_Evidence__c?: string;
	AR_V1_Q0_1__c?: string;
	AR_V1_Q0_2__c?: number;
	AR_V1_Q1_1_a__c?: string;
	AR_V1_Q1_1_b__c?: string;
	AR_V1_Q1_1_c__c?: string;
	AR_V1_Q1_2__c?: string;
	AR_V1_Q1_3__c?: string;
	AR_V1_Q1_4__c?: string;
	AR_V1_Q2_1_a__c?: number;
	AR_V1_Q2_1_b__c?: string;
	AR_V1_Q2_2__c?: string;
	AR_V1_Q2_3__c?: string;
	AR_V1_Q2_4__c?: string;
	AR_V1_Q2_5_a__c?: string;
	AR_V1_Q2_5_b__c?: string;
	AR_V1_Q2_5_c__c?: string;
	AR_V1_Q2_5_d__c?: string;
	AR_V1_Q2_5_e__c?: string;
	AR_V1_Q2_5_f__c?: string;
	AR_V1_Q2_5_g__c?: string;
	AR_V1_Q2_5_h__c?: string;
	AR_V1_Q2_5_i__c?: string;
	AR_V1_Q2_5_j__c?: string;
	AR_V1_Q2_6_a__c?: number;
	AR_V1_Q2_6_b__c?: string;
	AR_V1_Q2_6_c__c?: string;
	AR_V1_Q3_1__c?: string;
	AR_V1_Q3_2_a__c?: boolean;
	AR_V1_Q3_2_b__c?: boolean;
	AR_V1_Q3_2_c__c?: boolean;
	AR_V1_Q3_2_d__c?: boolean;
	AR_V1_Q3_2_e__c?: boolean;
	AR_V1_Q3_2_none__c?: boolean;
	AR_V1_Q3_3_a__c?: number;
	AR_V1_Q3_3_b__c?: string;
	AR_V1_Q3_4_a__c?: boolean;
	AR_V1_Q3_4_b__c?: boolean;
	AR_V1_Q3_4_c__c?: boolean;
	AR_V1_Q3_4_d__c?: boolean;
	AR_V1_Q3_4_none__c?: boolean;
	AR_V1_Q3_4_notes__c?: string;
	AR_V1_Q4_1_a__c?: number;
	AR_V1_Q4_1_b__c?: string;
	AR_V1_Q4_2_a__c?: number;
	AR_V1_Q4_2_b__c?: string;
	AR_V1_Q4_3__c?: number;
	AR_V1_Q4_4_i__c?: boolean;
	AR_V1_Q4_4_ii__c?: boolean;
	AR_V1_Q4_4_iii__c?: boolean;
	AR_V1_Q4_4_iv__c?: boolean;
	AR_V1_Q4_4_none__c?: boolean;
	AR_V1_Q4_5__c?: number;
	AR_V1_Q4_6_a__c?: string;
	AR_V1_Q4_6_b__c?: number;
	AR_V1_Q4_6_c__c?: string;
	AR_V1_Q4_7_a_i__c?: boolean;
	AR_V1_Q4_7_a_ii__c?: boolean;
	AR_V1_Q4_7_a_iii__c?: boolean;
	AR_V1_Q4_7_a_iv__c?: boolean;
	AR_V1_Q4_7_a_none__c?: boolean;
	AR_V1_Q4_7_a_v__c?: boolean;
	AR_V1_Q4_7_b_i__c?: string;
	AR_V1_Q4_7_b_ii__c?: string;
	AR_V1_Q4_7_b_iii__c?: string;
	AR_V1_Q4_7_b_iv__c?: string;
	AR_V1_Q4_7_b_v__c?: string;
	AR_V1_Q4_7_notes__c?: string;
	AR_V1_Q4_8_a__c?: boolean;
	AR_V1_Q4_8_b__c?: boolean;
	AR_V1_Q4_8_c__c?: boolean;
	AR_V1_Q4_8_d__c?: boolean;
	AR_V1_Q4_8_e__c?: boolean;
	AR_V1_Q4_8_f__c?: boolean;
	AR_V1_Q4_8_notes__c?: string;
	AR_V1_Q5_1_a__c?: number;
	AR_V1_Q5_1_b__c?: string;
	AR_V1_Q5_2_a__c?: boolean;
	AR_V1_Q5_2_b__c?: boolean;
	AR_V1_Q5_2_c__c?: boolean;
	AR_V1_Q5_2_d__c?: boolean;
	AR_V1_Q5_2_e__c?: boolean;
	AR_V1_Q5_2_f__c?: boolean;
	AR_V1_Q5_2_f_notes__c?: string;
	AR_V1_Q5_2_none__c?: boolean;
	AR_V1_Q6_1_a__c?: boolean;
	AR_V1_Q6_1_b__c?: boolean;
	AR_V1_Q6_1_c__c?: boolean;
	AR_V1_Q6_1_d__c?: boolean;
	AR_V1_Q6_1_e__c?: boolean;
	AR_V1_Q6_1_f__c?: boolean;
	AR_V1_Q6_1_g__c?: boolean;
	AR_V1_Q6_1_h__c?: boolean;
	AR_V1_Q6_1_h_notes__c?: string;
	AR_V1_Q6_1_i__c?: boolean;
	AR_V1_Q6_1_none__c?: boolean;
	AR_V1_Q6_2_a__c?: number;
	AR_V1_Q6_2_b__c?: string;
	AR_V1_Q6_2_c_i__c?: boolean;
	AR_V1_Q6_2_c_ii__c?: boolean;
	AR_V1_Q6_2_c_iii__c?: boolean;
	AR_V1_Q6_2_c_iv__c?: boolean;
	AR_V1_Q6_2_c_none__c?: boolean;
	AR_V1_Q6_2_c_v__c?: boolean;
	AR_V1_Q6_2_c_v_notes__c?: string;
	AR_V1_Q7_1_a__c?: boolean;
	AR_V1_Q7_1_b__c?: boolean;
	AR_V1_Q7_1_c__c?: boolean;
	AR_V1_Q7_1_d__c?: boolean;
	AR_V1_Q7_1_e__c?: boolean;
	AR_V1_Q7_1_e_notes__c?: string;
	AR_V1_Q7_1_none__c?: boolean;
	AR_V1_Q7_2_a__c?: boolean;
	AR_V1_Q7_2_b__c?: boolean;
	AR_V1_Q7_2_c__c?: boolean;
	AR_V1_Q7_2_d__c?: boolean;
	AR_V1_Q7_2_e__c?: boolean;
	AR_V1_Q7_2_f__c?: boolean;
	AR_V1_Q7_2_g__c?: boolean;
	AR_V1_Q7_2_h__c?: boolean;
	AR_V1_Q7_2_i__c?: boolean;
	AR_V1_Q7_2_none__c?: boolean;
	AR_V1_Q7_3_a__c?: boolean;
	AR_V1_Q7_3_b__c?: boolean;
	AR_V1_Q7_3_c__c?: boolean;
	AR_V1_Q7_3_d__c?: boolean;
	AR_V1_Q7_3_e__c?: boolean;
	AR_V1_Q7_3_f__c?: boolean;
	AR_V1_Q7_3_g__c?: boolean;
	AR_V1_Q7_3_h__c?: boolean;
	AR_V1_Q7_3_i__c?: boolean;
	AR_V1_Q7_3_none__c?: boolean;
	AR_V1_Q7_4_a__c?: boolean;
	AR_V1_Q7_4_b__c?: boolean;
	AR_V1_Q7_4_c__c?: boolean;
	AR_V1_Q7_4_d__c?: boolean;
	AR_V1_Q7_4_e__c?: boolean;
	AR_V1_Q7_4_f__c?: boolean;
	AR_V1_Q7_4_g__c?: boolean;
	AR_V1_Q7_4_h__c?: boolean;
	AR_V1_Q7_4_i__c?: boolean;
	AR_V1_Q7_4_none__c?: boolean;
	AR_V1_Q8_1_Selected_Materials__c?: string;
	AR_V1_Q8_1_a_i__c?: number;
	AR_V1_Q8_1_a_ii__c?: number;
	AR_V1_Q8_1_a_iii__c?: number;
	AR_V1_Q8_1_a_iv__c?: number;
	AR_V1_Q8_1_a_v__c?: number;
	AR_V1_Q8_1_a_vi__c?: number;
	AR_V1_Q8_1_accuracy__c?: string;
	AR_V1_Q8_1_b_i__c?: number;
	AR_V1_Q8_1_b_ii__c?: number;
	AR_V1_Q8_1_b_iii__c?: number;
	AR_V1_Q8_1_b_iv__c?: number;
	AR_V1_Q8_1_b_v__c?: number;
	AR_V1_Q8_1_b_vi__c?: number;
	AR_V1_Q8_1_c_i__c?: number;
	AR_V1_Q8_1_c_ii__c?: number;
	AR_V1_Q8_1_c_iii__c?: number;
	AR_V1_Q8_1_c_iv__c?: number;
	AR_V1_Q8_1_c_v__c?: number;
	AR_V1_Q8_1_c_vi__c?: number;
	AR_V1_Q8_1_d_i__c?: number;
	AR_V1_Q8_1_d_ii__c?: number;
	AR_V1_Q8_1_d_iii__c?: number;
	AR_V1_Q8_1_d_iv__c?: number;
	AR_V1_Q8_1_d_v__c?: number;
	AR_V1_Q8_1_d_vi__c?: number;
	AR_V1_Q8_1_e_i__c?: number;
	AR_V1_Q8_1_e_ii__c?: number;
	AR_V1_Q8_1_e_iii__c?: number;
	AR_V1_Q8_1_e_iv__c?: number;
	AR_V1_Q8_1_e_v__c?: number;
	AR_V1_Q8_1_e_vi__c?: number;
	AR_V1_Q8_1_f_i__c?: number;
	AR_V1_Q8_1_f_ii__c?: number;
	AR_V1_Q8_1_f_iii__c?: number;
	AR_V1_Q8_1_f_iv__c?: number;
	AR_V1_Q8_1_f_v__c?: number;
	AR_V1_Q8_1_f_vi__c?: number;
	AR_V1_Q8_1_g_i__c?: number;
	AR_V1_Q8_1_g_ii__c?: number;
	AR_V1_Q8_1_g_iii__c?: number;
	AR_V1_Q8_1_g_iv__c?: number;
	AR_V1_Q8_1_g_v__c?: number;
	AR_V1_Q8_1_g_vi__c?: number;
	AR_V1_Q8_1_h_i__c?: number;
	AR_V1_Q8_1_h_ii__c?: number;
	AR_V1_Q8_1_h_iii__c?: number;
	AR_V1_Q8_1_h_iv__c?: number;
	AR_V1_Q8_1_h_v__c?: number;
	AR_V1_Q8_1_h_vi__c?: number;
	AR_V1_Q8_1_i_i__c?: number;
	AR_V1_Q8_1_i_ii__c?: number;
	AR_V1_Q8_1_i_iii__c?: number;
	AR_V1_Q8_1_i_iv__c?: number;
	AR_V1_Q8_1_i_v__c?: number;
	AR_V1_Q8_1_i_vi__c?: number;
	AR_V1_Q8_1_j_i__c?: number;
	AR_V1_Q8_1_j_ii__c?: number;
	AR_V1_Q8_1_j_iii__c?: number;
	AR_V1_Q8_1_j_iv__c?: number;
	AR_V1_Q8_1_j_v__c?: number;
	AR_V1_Q8_1_j_vi__c?: number;
	AR_V1_Q8_1_k_i__c?: number;
	AR_V1_Q8_1_k_ii__c?: number;
	AR_V1_Q8_1_k_iii__c?: number;
	AR_V1_Q8_1_k_iv__c?: number;
	AR_V1_Q8_1_k_v__c?: number;
	AR_V1_Q8_1_k_vi__c?: number;
	AR_V1_Q8_1_l_i__c?: number;
	AR_V1_Q8_1_l_ii__c?: number;
	AR_V1_Q8_1_l_iii__c?: number;
	AR_V1_Q8_1_l_iv__c?: number;
	AR_V1_Q8_1_l_v__c?: number;
	AR_V1_Q8_1_l_vi__c?: number;
	AR_V1_Q8_1_m_i__c?: number;
	AR_V1_Q8_1_m_ii__c?: number;
	AR_V1_Q8_1_m_iii__c?: number;
	AR_V1_Q8_1_m_iv__c?: number;
	AR_V1_Q8_1_m_v__c?: number;
	AR_V1_Q8_1_m_vi__c?: number;
	AR_V1_Q8_1_n_i__c?: number;
	AR_V1_Q8_1_n_ii__c?: number;
	AR_V1_Q8_1_n_iii__c?: number;
	AR_V1_Q8_1_n_iv__c?: number;
	AR_V1_Q8_1_n_v__c?: number;
	AR_V1_Q8_1_n_vi__c?: number;
	AR_V1_Q8_1_o_i__c?: number;
	AR_V1_Q8_1_o_ii__c?: number;
	AR_V1_Q8_1_o_iii__c?: number;
	AR_V1_Q8_1_o_iv__c?: number;
	AR_V1_Q8_1_o_v__c?: number;
	AR_V1_Q8_1_o_vi__c?: number;
	AR_V1_Q8_1_p_i__c?: number;
	AR_V1_Q8_1_p_ii__c?: number;
	AR_V1_Q8_1_p_iii__c?: number;
	AR_V1_Q8_1_p_iv__c?: number;
	AR_V1_Q8_1_p_v__c?: number;
	AR_V1_Q8_1_p_vi__c?: number;
	AR_V1_Q8_1_q_i__c?: number;
	AR_V1_Q8_1_q_ii__c?: number;
	AR_V1_Q8_1_q_iii__c?: number;
	AR_V1_Q8_1_q_iv__c?: number;
	AR_V1_Q8_1_q_v__c?: number;
	AR_V1_Q8_1_q_vi__c?: number;
	AR_V1_Q8_1_r_i__c?: number;
	AR_V1_Q8_1_r_ii__c?: number;
	AR_V1_Q8_1_r_iii__c?: number;
	AR_V1_Q8_1_r_iv__c?: number;
	AR_V1_Q8_1_r_v__c?: number;
	AR_V1_Q8_1_r_vi__c?: number;
	AR_V1_Q8_1_s_i__c?: number;
	AR_V1_Q8_1_s_ii__c?: number;
	AR_V1_Q8_1_s_iii__c?: number;
	AR_V1_Q8_1_s_iv__c?: number;
	AR_V1_Q8_1_s_v__c?: number;
	AR_V1_Q8_1_s_vi__c?: number;
	AR_V1_Q8_1_t_i__c?: number;
	AR_V1_Q8_1_t_ii__c?: number;
	AR_V1_Q8_1_t_iii__c?: number;
	AR_V1_Q8_1_t_iv__c?: number;
	AR_V1_Q8_1_t_v__c?: number;
	AR_V1_Q8_1_t_vi__c?: number;
	AR_V1_Q8_1_u_i__c?: number;
	AR_V1_Q8_1_u_ii__c?: number;
	AR_V1_Q8_1_u_iii__c?: number;
	AR_V1_Q8_1_u_iv__c?: number;
	AR_V1_Q8_1_u_v__c?: number;
	AR_V1_Q8_1_u_vi__c?: number;
	AR_V1_Q8_1_v_i__c?: number;
	AR_V1_Q8_1_v_ii__c?: number;
	AR_V1_Q8_1_v_iii__c?: number;
	AR_V1_Q8_1_v_iv__c?: number;
	AR_V1_Q8_1_v_v__c?: number;
	AR_V1_Q8_1_v_vi__c?: number;
	AR_V1_Q8_1_w_i__c?: number;
	AR_V1_Q8_1_w_ii__c?: number;
	AR_V1_Q8_1_w_iii__c?: number;
	AR_V1_Q8_1_w_iv__c?: number;
	AR_V1_Q8_1_w_v__c?: number;
	AR_V1_Q8_1_w_vi__c?: number;
	AR_V1_Q9_1__c?: string;
	AR_V1_Q9_2__c?: string;
	AR_V1_Q9_3__c?: string;
	AR_V1_Q9_4__c?: string;
	AR_V1_Q9_5__c?: string;
	AR_V1_Sect_1_Raw_Score__c?: number;
	AR_V1_Sect_1_Weighted_Score__c?: number;
	AR_V1_Sect_2_Raw_Score__c?: number;
	AR_V1_Sect_2_Weighted_Score__c?: number;
	AR_V1_Sect_3_Raw_Score__c?: number;
	AR_V1_Sect_3_Weighted_Score__c?: number;
	AR_V1_Sect_4_Raw_Score__c?: number;
	AR_V1_Sect_4_Weighted_Score__c?: number;
	AR_V1_Sect_5_Raw_Score__c?: number;
	AR_V1_Sect_5_Weighted_Score__c?: number;
	AR_V1_Sect_6_Raw_Score__c?: number;
	AR_V1_Sect_6_Weighted_Score__c?: number;
	AR_V1_Sect_7_Calc__c?: number;
	AR_V1_Sect_7_Raw_Score__c?: number;
	AR_V1_Sect_7_Weighted_Score__c?: number;
	External_Id__c?: string;
	Member_Account__c?: string;
	Name_Equals_Naming_Convention__c?: boolean;
	Naming_Convention__c?: string;
	No_additional_information__c?: boolean;
	Reporting_period_start_date__c?: Date;
	Submitted_By__c?: string;
	Submitted_On__c?: Date;
	Subsidiaries_Excluded__c?: string;
	Subsidiaries_Included__c?: string;
	T_C_s_Signed_By__c?: string;
	T_C_s_Signed__c?: Date;
	AR_V1_Q4_8_none__c?: boolean;
	Overall_Raw_Score__c?: number;
	AR_V1_Sect_1_Performance_Level__c?: string;
	Overall_Weighted_Score__c?: number;
	Overall_Performance_Level__c?: string;
	AR_V1_Sect_2_Performance_Level__c?: string;
	AR_V1_Sect_3_Performance_Level__c?: string;
	AR_V1_Sect_4_Performance_Level__c?: string;
	AR_V1_Sect_5_Performance_Level__c?: string;
	AR_V1_Sect_6_Performance_Level__c?: string;
	AR_V1_Sect_7_Performance_Level__c?: string;
	AR_V1_Q4_8_g__c?: boolean;
	Submitted_Name__c?: string;
	Submitted_Company__c?: string;
	Submitted_ABN__c?: number;
	No_Subsidiaries__c?: boolean;
	Subsidiaries_ABN_Included__c?: string;
	Subsidiaries_ABN_Excluded__c?: string;
	Subsidiaries_ANZSIC_Code_Excluded__c?: string;
	Subsidiaries_ANZSIC_Code_Included__c?: string;
	Audit_Declaration__c?: boolean;
	AR_V1_Q8_1_File_url__c?: string;
	Additional_Feedback__c?: string;
}
