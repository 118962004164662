import { BaseDto } from "../baseDto"
/* ProductAttributeBaseDto generated at 2024-09-25 15:25:20.542 +10:00*/
export class ProductAttributeBaseDto extends BaseDto {
	ProductAttributeId?: string;
	ProductId?: string;
	ProductComponentId?: string;
	ProductComponentGroupId?: string;
	ProductSKUId?: string;
	ObjectAttributeVersionId?: string;
	AttributeJSON?: string;
	StatusCode?: string;
}
