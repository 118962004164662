import { BaseDto } from "../baseDto"
/* ObjectAttributeVersionBaseDto generated at 2024-09-25 15:25:20.489 +10:00*/
export class ObjectAttributeVersionBaseDto extends BaseDto {
	ObjectAttributeVersionId?: string;
	ObjectAttributeId?: string;
	ObjAttrVersionNo?: number;
	ObjAttrJSON?: string;
	IsUsed?: boolean;
	StatusCode?: string;
}
