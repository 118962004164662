import { BaseDto } from "../baseDto"
/* ANZPACReportBaseDto generated at 2024-09-25 15:25:20.296 +10:00*/
export class ANZPACReportBaseDto extends BaseDto {
	ANZPACReportId?: string;
	OrganisationId?: string;
	ReportType?: string;
	ReportingYear?: number;
	DateStarted?: Date;
	DateCompleted?: Date;
	DateSubmitted?: Date;
	DataEnteredBy?: string;
	ReportSignedBy?: string;
	ReportSignedPosition?: string;
	DateReportSigned?: Date;
	OrganisationName?: string;
	Industry?: string;
	ReportingPeriod?: string;
	GeographicLocation?: string;
	ShortDescription?: string;
	WebsiteUrl?: string;
	AnnualTurnover?: string;
	CurrencyCode?: string;
	SupporterCategory?: string;
	Verification_Q1?: boolean;
	Verification_Q2?: boolean;
	Verification_Q3?: boolean;
	Verification_Q4?: boolean;
	BaseMetric_Tonnage?: number;
	BaseMetric_Comment?: string;
	RA_OtherPlastic?: string;
	RA_IsRecyclingExists?: boolean;
	RA_Evidence?: string;
	T1_Activity1?: boolean;
	T1_Activity2?: boolean;
	T2_Activity1?: boolean;
	T2_Activity2?: boolean;
	T2_Activity3?: boolean;
	T2_Activity4?: boolean;
	T3_Activity1?: boolean;
	T3_Activity2?: boolean;
	T3_IsCollecting?: boolean;
	T3_IsSorting?: boolean;
	T3_IsRecycling?: boolean;
	T4_Activity1?: boolean;
	T4_Activity2?: boolean;
	T4_Question1?: boolean;
	Feedback?: string;
	OtherGeographicLocation?: string;
	OtherIndustry?: string;
	OtherSupporterCategory?: string;
	StatusCode?: string;
	ReportAuthorise?: boolean;
}
