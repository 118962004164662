import { BaseDto } from "../baseDto"
/* ExtTbl01BaseDto generated at 2024-09-25 15:25:20.426 +10:00*/
export class ExtTbl01BaseDto extends BaseDto {
	ExtRecordId?: string;
	UUIDFld001?: string;
	UUIDFld002?: string;
	UUIDFld003?: string;
	UUIDFld004?: string;
	UUIDFld005?: string;
	UUIDFld006?: string;
	UUIDFld007?: string;
	UUIDFld008?: string;
	UUIDFld009?: string;
	UUIDFld010?: string;
	UUIDFld011?: string;
	UUIDFld012?: string;
	UUIDFld013?: string;
	UUIDFld014?: string;
	UUIDFld015?: string;
	UUIDFld016?: string;
	UUIDFld017?: string;
	UUIDFld018?: string;
	UUIDFld019?: string;
	UUIDFld020?: string;
	UUIDFld021?: string;
	UUIDFld022?: string;
	UUIDFld023?: string;
	UUIDFld024?: string;
	UUIDFld025?: string;
	UUIDFld026?: string;
	UUIDFld027?: string;
	UUIDFld028?: string;
	UUIDFld029?: string;
	UUIDFld030?: string;
	UUIDFld031?: string;
	UUIDFld032?: string;
	UUIDFld033?: string;
	UUIDFld034?: string;
	UUIDFld035?: string;
	UUIDFld036?: string;
	UUIDFld037?: string;
	UUIDFld038?: string;
	UUIDFld039?: string;
	UUIDFld040?: string;
	UUIDFld041?: string;
	UUIDFld042?: string;
	UUIDFld043?: string;
	UUIDFld044?: string;
	UUIDFld045?: string;
	UUIDFld046?: string;
	UUIDFld047?: string;
	UUIDFld048?: string;
	UUIDFld049?: string;
	UUIDFld050?: string;
	UUIDFld051?: string;
	UUIDFld052?: string;
	UUIDFld053?: string;
	UUIDFld054?: string;
	UUIDFld055?: string;
	UUIDFld056?: string;
	UUIDFld057?: string;
	UUIDFld058?: string;
	UUIDFld059?: string;
	UUIDFld060?: string;
	UUIDFld061?: string;
	UUIDFld062?: string;
	UUIDFld063?: string;
	UUIDFld064?: string;
	UUIDFld065?: string;
	UUIDFld066?: string;
	UUIDFld067?: string;
	UUIDFld068?: string;
	UUIDFld069?: string;
	UUIDFld070?: string;
	UUIDFld071?: string;
	UUIDFld072?: string;
	UUIDFld073?: string;
	UUIDFld074?: string;
	UUIDFld075?: string;
	UUIDFld076?: string;
	UUIDFld077?: string;
	UUIDFld078?: string;
	UUIDFld079?: string;
	UUIDFld080?: string;
	UUIDFld081?: string;
	UUIDFld082?: string;
	UUIDFld083?: string;
	UUIDFld084?: string;
	UUIDFld085?: string;
	UUIDFld086?: string;
	UUIDFld087?: string;
	UUIDFld088?: string;
	UUIDFld089?: string;
	UUIDFld090?: string;
	UUIDFld091?: string;
	UUIDFld092?: string;
	UUIDFld093?: string;
	UUIDFld094?: string;
	UUIDFld095?: string;
	UUIDFld096?: string;
	UUIDFld097?: string;
	UUIDFld098?: string;
	UUIDFld099?: string;
	UUIDFld100?: string;
	IntFld001?: number;
	IntFld002?: number;
	IntFld003?: number;
	IntFld004?: number;
	IntFld005?: number;
	IntFld006?: number;
	IntFld007?: number;
	IntFld008?: number;
	IntFld009?: number;
	IntFld010?: number;
	IntFld011?: number;
	IntFld012?: number;
	IntFld013?: number;
	IntFld014?: number;
	IntFld015?: number;
	IntFld016?: number;
	IntFld017?: number;
	IntFld018?: number;
	IntFld019?: number;
	IntFld020?: number;
	IntFld021?: number;
	IntFld022?: number;
	IntFld023?: number;
	IntFld024?: number;
	IntFld025?: number;
	IntFld026?: number;
	IntFld027?: number;
	IntFld028?: number;
	IntFld029?: number;
	IntFld030?: number;
	IntFld031?: number;
	IntFld032?: number;
	IntFld033?: number;
	IntFld034?: number;
	IntFld035?: number;
	IntFld036?: number;
	IntFld037?: number;
	IntFld038?: number;
	IntFld039?: number;
	IntFld040?: number;
	IntFld041?: number;
	IntFld042?: number;
	IntFld043?: number;
	IntFld044?: number;
	IntFld045?: number;
	IntFld046?: number;
	IntFld047?: number;
	IntFld048?: number;
	IntFld049?: number;
	IntFld050?: number;
	IntFld051?: number;
	IntFld052?: number;
	IntFld053?: number;
	IntFld054?: number;
	IntFld055?: number;
	IntFld056?: number;
	IntFld057?: number;
	IntFld058?: number;
	IntFld059?: number;
	IntFld060?: number;
	IntFld061?: number;
	IntFld062?: number;
	IntFld063?: number;
	IntFld064?: number;
	IntFld065?: number;
	IntFld066?: number;
	IntFld067?: number;
	IntFld068?: number;
	IntFld069?: number;
	IntFld070?: number;
	IntFld071?: number;
	IntFld072?: number;
	IntFld073?: number;
	IntFld074?: number;
	IntFld075?: number;
	IntFld076?: number;
	IntFld077?: number;
	IntFld078?: number;
	IntFld079?: number;
	IntFld080?: number;
	DblFld001?: number;
	DblFld002?: number;
	DblFld003?: number;
	DblFld004?: number;
	DblFld005?: number;
	DblFld006?: number;
	DblFld007?: number;
	DblFld008?: number;
	DblFld009?: number;
	DblFld010?: number;
	DblFld011?: number;
	DblFld012?: number;
	DblFld013?: number;
	DblFld014?: number;
	DblFld015?: number;
	DblFld016?: number;
	DblFld017?: number;
	DblFld018?: number;
	DblFld019?: number;
	DblFld020?: number;
	DblFld021?: number;
	DblFld022?: number;
	DblFld023?: number;
	DblFld024?: number;
	DblFld025?: number;
	DblFld026?: number;
	DblFld027?: number;
	DblFld028?: number;
	DblFld029?: number;
	DblFld030?: number;
	DblFld031?: number;
	DblFld032?: number;
	DblFld033?: number;
	DblFld034?: number;
	DblFld035?: number;
	DblFld036?: number;
	DblFld037?: number;
	DblFld038?: number;
	DblFld039?: number;
	DblFld040?: number;
	DblFld041?: number;
	DblFld042?: number;
	DblFld043?: number;
	DblFld044?: number;
	DblFld045?: number;
	DblFld046?: number;
	DblFld047?: number;
	DblFld048?: number;
	DblFld049?: number;
	DblFld050?: number;
	DblFld051?: number;
	DblFld052?: number;
	DblFld053?: number;
	DblFld054?: number;
	DblFld055?: number;
	DblFld056?: number;
	DblFld057?: number;
	DblFld058?: number;
	DblFld059?: number;
	DblFld060?: number;
	DblFld061?: number;
	DblFld062?: number;
	DblFld063?: number;
	DblFld064?: number;
	DblFld065?: number;
	DblFld066?: number;
	DblFld067?: number;
	DblFld068?: number;
	DblFld069?: number;
	DblFld070?: number;
	DblFld071?: number;
	DblFld072?: number;
	DblFld073?: number;
	DblFld074?: number;
	DblFld075?: number;
	DblFld076?: number;
	DblFld077?: number;
	DblFld078?: number;
	DblFld079?: number;
	DblFld080?: number;
	DtFld001?: Date;
	DtFld002?: Date;
	DtFld003?: Date;
	DtFld004?: Date;
	DtFld005?: Date;
	DtFld006?: Date;
	DtFld007?: Date;
	DtFld008?: Date;
	DtFld009?: Date;
	DtFld010?: Date;
	DtFld011?: Date;
	DtFld012?: Date;
	DtFld013?: Date;
	DtFld014?: Date;
	DtFld015?: Date;
	DtFld016?: Date;
	DtFld017?: Date;
	DtFld018?: Date;
	DtFld019?: Date;
	DtFld020?: Date;
	DtFld021?: Date;
	DtFld022?: Date;
	DtFld023?: Date;
	DtFld024?: Date;
	DtFld025?: Date;
	DtFld026?: Date;
	DtFld027?: Date;
	DtFld028?: Date;
	DtFld029?: Date;
	DtFld030?: Date;
	DtFld031?: Date;
	DtFld032?: Date;
	DtFld033?: Date;
	DtFld034?: Date;
	DtFld035?: Date;
	DtFld036?: Date;
	DtFld037?: Date;
	DtFld038?: Date;
	DtFld039?: Date;
	DtFld040?: Date;
	DtFld041?: Date;
	DtFld042?: Date;
	DtFld043?: Date;
	DtFld044?: Date;
	DtFld045?: Date;
	DtFld046?: Date;
	DtFld047?: Date;
	DtFld048?: Date;
	DtFld049?: Date;
	DtFld050?: Date;
	DtFld051?: Date;
	DtFld052?: Date;
	DtFld053?: Date;
	DtFld054?: Date;
	DtFld055?: Date;
	DtFld056?: Date;
	DtFld057?: Date;
	DtFld058?: Date;
	DtFld059?: Date;
	DtFld060?: Date;
	DtFld061?: Date;
	DtFld062?: Date;
	DtFld063?: Date;
	DtFld064?: Date;
	DtFld065?: Date;
	DtFld066?: Date;
	DtFld067?: Date;
	DtFld068?: Date;
	DtFld069?: Date;
	DtFld070?: Date;
	DtFld071?: Date;
	DtFld072?: Date;
	DtFld073?: Date;
	DtFld074?: Date;
	DtFld075?: Date;
	DtFld076?: Date;
	DtFld077?: Date;
	DtFld078?: Date;
	DtFld079?: Date;
	DtFld080?: Date;
	BitFld001?: boolean;
	BitFld002?: boolean;
	BitFld003?: boolean;
	BitFld004?: boolean;
	BitFld005?: boolean;
	BitFld006?: boolean;
	BitFld007?: boolean;
	BitFld008?: boolean;
	BitFld009?: boolean;
	BitFld010?: boolean;
	BitFld011?: boolean;
	BitFld012?: boolean;
	BitFld013?: boolean;
	BitFld014?: boolean;
	BitFld015?: boolean;
	BitFld016?: boolean;
	BitFld017?: boolean;
	BitFld018?: boolean;
	BitFld019?: boolean;
	BitFld020?: boolean;
	BitFld021?: boolean;
	BitFld022?: boolean;
	BitFld023?: boolean;
	BitFld024?: boolean;
	BitFld025?: boolean;
	BitFld026?: boolean;
	BitFld027?: boolean;
	BitFld028?: boolean;
	BitFld029?: boolean;
	BitFld030?: boolean;
	BitFld031?: boolean;
	BitFld032?: boolean;
	BitFld033?: boolean;
	BitFld034?: boolean;
	BitFld035?: boolean;
	BitFld036?: boolean;
	BitFld037?: boolean;
	BitFld038?: boolean;
	BitFld039?: boolean;
	BitFld040?: boolean;
	BitFld041?: boolean;
	BitFld042?: boolean;
	BitFld043?: boolean;
	BitFld044?: boolean;
	BitFld045?: boolean;
	BitFld046?: boolean;
	BitFld047?: boolean;
	BitFld048?: boolean;
	BitFld049?: boolean;
	BitFld050?: boolean;
	BitFld051?: boolean;
	BitFld052?: boolean;
	BitFld053?: boolean;
	BitFld054?: boolean;
	BitFld055?: boolean;
	BitFld056?: boolean;
	BitFld057?: boolean;
	BitFld058?: boolean;
	BitFld059?: boolean;
	BitFld060?: boolean;
	BitFld061?: boolean;
	BitFld062?: boolean;
	BitFld063?: boolean;
	BitFld064?: boolean;
	BitFld065?: boolean;
	BitFld066?: boolean;
	BitFld067?: boolean;
	BitFld068?: boolean;
	BitFld069?: boolean;
	BitFld070?: boolean;
	BitFld071?: boolean;
	BitFld072?: boolean;
	BitFld073?: boolean;
	BitFld074?: boolean;
	BitFld075?: boolean;
	BitFld076?: boolean;
	BitFld077?: boolean;
	BitFld078?: boolean;
	BitFld079?: boolean;
	BitFld080?: boolean;
	TxtSFld001?: string;
	TxtSFld002?: string;
	TxtSFld003?: string;
	TxtSFld004?: string;
	TxtSFld005?: string;
	TxtSFld006?: string;
	TxtSFld007?: string;
	TxtSFld008?: string;
	TxtSFld009?: string;
	TxtSFld010?: string;
	TxtSFld011?: string;
	TxtSFld012?: string;
	TxtSFld013?: string;
	TxtSFld014?: string;
	TxtSFld015?: string;
	TxtSFld016?: string;
	TxtSFld017?: string;
	TxtSFld018?: string;
	TxtSFld019?: string;
	TxtSFld020?: string;
	TxtSFld021?: string;
	TxtSFld022?: string;
	TxtSFld023?: string;
	TxtSFld024?: string;
	TxtSFld025?: string;
	TxtSFld026?: string;
	TxtSFld027?: string;
	TxtSFld028?: string;
	TxtSFld029?: string;
	TxtSFld030?: string;
	TxtSFld031?: string;
	TxtSFld032?: string;
	TxtSFld033?: string;
	TxtSFld034?: string;
	TxtSFld035?: string;
	TxtSFld036?: string;
	TxtSFld037?: string;
	TxtSFld038?: string;
	TxtSFld039?: string;
	TxtSFld040?: string;
	TxtSFld041?: string;
	TxtSFld042?: string;
	TxtSFld043?: string;
	TxtSFld044?: string;
	TxtSFld045?: string;
	TxtSFld046?: string;
	TxtSFld047?: string;
	TxtSFld048?: string;
	TxtSFld049?: string;
	TxtSFld050?: string;
	TxtSFld051?: string;
	TxtSFld052?: string;
	TxtSFld053?: string;
	TxtSFld054?: string;
	TxtSFld055?: string;
	TxtSFld056?: string;
	TxtSFld057?: string;
	TxtSFld058?: string;
	TxtSFld059?: string;
	TxtSFld060?: string;
	TxtSFld061?: string;
	TxtSFld062?: string;
	TxtSFld063?: string;
	TxtSFld064?: string;
	TxtSFld065?: string;
	TxtSFld066?: string;
	TxtSFld067?: string;
	TxtSFld068?: string;
	TxtSFld069?: string;
	TxtSFld070?: string;
	TxtSFld071?: string;
	TxtSFld072?: string;
	TxtSFld073?: string;
	TxtSFld074?: string;
	TxtSFld075?: string;
	TxtSFld076?: string;
	TxtSFld077?: string;
	TxtSFld078?: string;
	TxtSFld079?: string;
	TxtSFld080?: string;
	TxtMFld001?: string;
	TxtMFld002?: string;
	TxtMFld003?: string;
	TxtMFld004?: string;
	TxtMFld005?: string;
	TxtMFld006?: string;
	TxtMFld007?: string;
	TxtMFld008?: string;
	TxtMFld009?: string;
	TxtMFld010?: string;
	TxtMFld011?: string;
	TxtMFld012?: string;
	TxtMFld013?: string;
	TxtMFld014?: string;
	TxtMFld015?: string;
	TxtMFld016?: string;
	TxtMFld017?: string;
	TxtMFld018?: string;
	TxtMFld019?: string;
	TxtMFld020?: string;
	TxtMFld021?: string;
	TxtMFld022?: string;
	TxtMFld023?: string;
	TxtMFld024?: string;
	TxtMFld025?: string;
	TxtMFld026?: string;
	TxtMFld027?: string;
	TxtMFld028?: string;
	TxtMFld029?: string;
	TxtMFld030?: string;
	TxtMFld031?: string;
	TxtMFld032?: string;
	TxtMFld033?: string;
	TxtMFld034?: string;
	TxtMFld035?: string;
	TxtMFld036?: string;
	TxtMFld037?: string;
	TxtMFld038?: string;
	TxtMFld039?: string;
	TxtMFld040?: string;
	TxtMFld041?: string;
	TxtMFld042?: string;
	TxtMFld043?: string;
	TxtMFld044?: string;
	TxtMFld045?: string;
	TxtMFld046?: string;
	TxtMFld047?: string;
	TxtMFld048?: string;
	TxtMFld049?: string;
	TxtMFld050?: string;
	TxtMFld051?: string;
	TxtMFld052?: string;
	TxtMFld053?: string;
	TxtMFld054?: string;
	TxtMFld055?: string;
	TxtMFld056?: string;
	TxtMFld057?: string;
	TxtMFld058?: string;
	TxtMFld059?: string;
	TxtMFld060?: string;
	TxtMFld061?: string;
	TxtMFld062?: string;
	TxtMFld063?: string;
	TxtMFld064?: string;
	TxtMFld065?: string;
	TxtMFld066?: string;
	TxtMFld067?: string;
	TxtMFld068?: string;
	TxtMFld069?: string;
	TxtMFld070?: string;
	TxtMFld071?: string;
	TxtMFld072?: string;
	TxtMFld073?: string;
	TxtMFld074?: string;
	TxtMFld075?: string;
	TxtMFld076?: string;
	TxtMFld077?: string;
	TxtMFld078?: string;
	TxtMFld079?: string;
	TxtMFld080?: string;
}
