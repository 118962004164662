import { BaseDto } from "../baseDto"
/* ARLProjectBaseDto generated at 2024-09-25 15:25:20.345 +10:00*/
export class ARLProjectBaseDto extends BaseDto {
	ARLProjectId?: string;
	RequestSource?: string;
	EnvironmentCode?: string;
	DateRequested?: Date;
	DateCompleted?: Date;
	DateProjectRequested?: Date;
	DateProjectCompleted?: Date;
	OrganisationId?: string;
	UserId?: string;
	ProjectId?: string;
	ProjectVersionNo?: number;
	PREP_OrganisationId?: number;
	PREP_UserId?: number;
	PREP_ProjectId?: number;
	Org_ReferenceId?: string;
	ProjectRequestJSON?: string;
	ProjectResponseJSON?: string;
	ARLProjectRequestJSON?: string;
	ARLProjectResponseJSON?: string;
	ExceptionJSON?: string;
	RetryCount?: number;
	StatusCode?: string;
}
