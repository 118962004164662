import { BaseDto } from "../baseDto"
/* EmailMessageBaseDto generated at 2024-09-25 15:25:20.409 +10:00*/
export class EmailMessageBaseDto extends BaseDto {
	EmailMessageId?: string;
	EmailSetId?: string;
	EmailResourceName?: string;
	EmailDirectionType?: string;
	SenderCode?: string;
	SenderUserId?: string;
	SenderEmail?: string;
	SenderName?: string;
	SenderReplyEmail?: string;
	SenderReplyName?: string;
	RecipientToJSON?: string;
	RecipientCCJSON?: string;
	RecipientBCCJSON?: string;
	EmailReferenceId?: string;
	EmailReference?: string;
	IsHtmlMessage?: boolean;
	EmailDate?: Date;
	EmailSubject?: string;
	EmailContent?: string;
	EmailAttachmentJSON?: string;
	SendDate?: Date;
	SentDate?: Date;
	ReferenceTypeCode?: string;
	ReferenceId?: string;
	External_RelatedReferenceId?: string;
	External_ReferenceId?: string;
	RetryCount?: number;
	InboundSender?: string;
	ReceivedDate?: Date;
	ProcessedDate?: Date;
	RelatedEmailMessageId?: string;
	EnvironmentCode?: string;
	EmailStatusCode?: string;
	StatusCode?: string;
}
