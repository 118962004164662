import { BaseDto } from "../baseDto"
/* SequenceNoBaseDto generated at 2024-09-25 15:25:20.646 +10:00*/
export class SequenceNoBaseDto extends BaseDto {
	SequenceNoId?: string;
	SequenceType?: string;
	ReferenceName?: string;
	LastSequenceNo?: number;
	Value?: string;
	StatusCode?: string;
}
