import { BaseDto } from "../baseDto"
/* SystemModelBaseDto generated at 2024-09-25 15:25:20.776 +10:00*/
export class SystemModelBaseDto extends BaseDto {
	SystemModelId?: string;
	ModelName?: string;
	ModelTypeCode?: string;
	IsOrgDb?: boolean;
	OrgDbVersion?: number;
	SchemaJSON?: string;
	StatusCode?: string;
}
